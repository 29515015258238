<template>
  <div>
    {{MainTitle}}
  </div>
</template>

<script>
export default {
  name: 'MainTitle',
  data: function () {
    return {
      MainTitle: "Make Choice .net"
    }
  }
}
</script>

<style scoped>

</style>
